<template>
  <div class="is-flex">
    <!-- <div class="color" :style="{ background: '#' + player.color }"></div> -->
    <player-avatar
      :player="player"
      :scale="2"></player-avatar>
    <div class="username">{{ player.username }}</div>
  </div>
</template>

<script>
export default {
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
